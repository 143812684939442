import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useAccount } from '../../common/AccountContext';

function AccountConnection() {
  const { getAccessTokenSilently } = useAuth0();
  const { account, setAccount } = useAccount();
  const [formFields, setFormFields] = React.useState({
    api_type: account?.company?.api_type || '',
    api_details: account?.company?.api_details || '',
  });
  const [editable, setEditable] = React.useState(true);

  const successToast = () => {
    setEditable(false);

    if (account?.company) {
      setAccount({
        ...account,
        company: {
          ...account.company,
          ...formFields,
        },
      });
    }
    return <b>Changes saved!</b>;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      formFields.api_type === account?.company?.api_type
      && formFields.api_details === account?.company?.api_details
    ) {
      return;
    }

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
      scope: 'openid profile email',
    });

    const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.aeoncharge.com/api'}/cpo/api-access`;
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formFields,
        company_id: account?.company?.id,
      }),
    };

    toast.promise(
      fetch(url, options).then((res) => res.json()),
      {
        loading: 'Saving...',
        success: successToast,
        error: <b>Could not save.</b>,
      },
    );
  };

  return (
    <div className="flex flex-col items-center text-lg h-full">
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-lg p-10 mt-10 space-y-10 w-full h-full"
      >
        <div>
          <label htmlFor="api_type" className="text-2xl text-primary">Backend Setup</label>
          <select
            name="api_type"
            id="api_type"
            value={formFields.api_type}
            onChange={(e) => setFormFields({ ...formFields, api_type: e.target.value })}
            disabled={!editable}
            className={`table-cell form-input border-none bg-gray-100 rounded-md w-full mt-6 ${(!formFields.api_type || !editable) && 'text-gray-500'}`}
          >
            <option value="">Choose a Backend Setup</option>
            <option value="REST">REST</option>
            <option value="GRAPHQL">GraphQL</option>
            <option value="OTHER">Other</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="api_details" className="text-2xl text-primary">Details</label>
          <textarea
            name="api_details"
            id="api_details"
            value={formFields.api_details}
            rows={10}
            maxLength={1000}
            placeholder="Enter description"
            onChange={(e) => setFormFields({ ...formFields, api_details: e.target.value })}
            disabled={!editable}
            className={`table-cell form-input border-none bg-gray-100 rounded-md w-full mt-6 ${!editable && 'text-gray-500'}`}
          />
          {
            editable
              ? (
                <button
                  type="submit"
                  className="bg-primary w-fit text-white rounded-lg py-2 px-14 mt-9 hover:bg-black self-end"
                >
                  Save
                </button>
              )
              : (
                <button
                  type="button"
                  onClick={() => setEditable(true)}
                  className="bg-primary w-fit text-white rounded-lg py-2 px-14 mt-9 hover:bg-black self-end"
                >
                  Edit

                </button>
              )
          }
        </div>
      </form>
    </div>
  );
}
export default AccountConnection;
