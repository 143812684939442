import React from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useAccount } from '../../common/AccountContext';

function Billing() {
  return (
    <div className="flex flex-col h-full space-y-8">
      <div id="card" className="flex flex-col">
        <div className="text-xl mb-4">
          Credits Summary:
        </div>
        <div className="rounded-md bg-white p-8">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <div>Total Amount Remaining</div>
              <div>$ xxxx</div>
            </div>
            <div className="flex flex-col">
              <div>Amount Used</div>
              <div>$ xxxx</div>
            </div>
          </div>
        </div>
      </div>
      <div id="card" className="flex flex-col">
        <div className="text-xl mb-4">
          Invoices:
        </div>
        <div className="rounded-md bg-white p-8">
          <table className="w-full">
            <thead>
              <tr>
                <th className="p-4 text-left">Issued Date</th>
                <th className="p-4 text-left">Status</th>
                <th className="p-4 text-left">Amount</th>
                <th className="p-4 text-left">Period</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-4">__-__-__</td>
                <td className="p-4">paid</td>
                <td className="p-4">$ ----</td>
                <td className="p-4">March 2022</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="card" className="flex flex-col">
        <div className="text-xl mb-4">
          Billing Information:
        </div>
        <div className="rounded-md bg-white p-8">
          Payment method: Cards (ACH Direct)
        </div>
      </div>
    </div>
  );
}

export default Billing;
