import React from 'react';
import { Link } from 'react-router-dom';

function Error() {
  return (
    <main className="h-screen w-screen flex">
      <div className="m-auto">
        <div className="flex justify-center mb-8">
          {/* <img src="/logo.png" alt="Logo" className="object-scale-down h-8 w-8" /> */}
          <svg className="h-16 w-16" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <g id="Layer_1-2" data-name="Layer 1-2">
              <rect width="400" height="400" fill="#f9f9f9" opacity="0" strokeWidth="0" />
              <polygon points="372.71 110.77 260.51 199.98 148.33 289.22 148.33 199.98 148.33 110.77 260.51 110.77 372.71 110.77" fill="#c10d32" fillRule="evenodd" strokeWidth="0" />
              <polygon points="27.29 289.22 72.68 253.14 118.07 217.02 118.07 253.14 118.07 289.22 72.68 289.22 27.29 289.22" fill="#c10d32" fillRule="evenodd" strokeWidth="0" />
            </g>
          </svg>
        </div>
        <h1 className="flex justify-center font-bold text-lg mb-2">404</h1>
        <p>Uhoh, we could not find what you were looking for!</p>
        <Link to="/" className="flex justify-center underline">Return to eMobilabs Hub</Link>
      </div>
    </main>
  );
}
export default Error;
