import React, { useEffect } from 'react';
import {
  Outlet, useLocation, useNavigate,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from './Spinner';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Onboarding from '../pages/Onboarding';
import WaitForApproval from '../pages/WaitForApproval';
import { useAccount } from '../common/AccountContext';

function ProtectedLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const { account, setAccount } = useAccount();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
          scope: 'openid profile email',
        });

        const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.emobilabs.com/api'}/fetch-account`;
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            auth0_user: user,
            sub: user?.sub,
            email: user?.email,
          }),
        };

        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then((response) => {
            if (!response) return;
            setAccount(response);

            if (response.approved) {
              navigate(pathname);
            }
          });
      } catch (error) {
        // @TODO - Make error display on screen
        // console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render spinner until 'approved' is fetched from DB and is NOT NULL
  if (!account) {
    return <Spinner />;
  }

  if (!account.approved) {
    if (account.onboarding_complete) {
      return <WaitForApproval />;
    }
    return <Onboarding setAccount={setAccount} />;
  }

  return (
    <div className="flex h-screen">
      {/* <!-- Left Content (Sidebar) --> */}
      <Sidebar />

      {/* <!-- Right Content --> */}
      <div className="flex flex-col w-full max-h-full bg-primarylight p-10 overflow-auto">

        {/* <!-- Top Nav --> */}
        <Topbar account={account} />

        {/* <!-- Outlet --> */}
        <Outlet />
      </div>
    </div>
  );
}
export default ProtectedLayout;
