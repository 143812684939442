import React from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { BsFillGearFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Account } from '../common/types';
import Greeting from './Greeting';
import SupportEmail from './SupportEmail';

function Topbar({ account }: { account: Account; }) {
  const { user, logout } = useAuth0();

  const [state, toggleState] = React.useState({
    updates: false,
    support: false,
    profile: false,
  });

  const [page, setPage] = React.useState('support-email');

  const renderSupportDropdown = () => {
    switch (page) {
      case 'support-call':
        return (
          <div className="p-4 justify-center">
            <p>
              Link below opens Calendly to schedule a call with our support team.
            </p>
            <div className="flex justify-center">
              <a
                href="https://calendly.com/kabinnam/30min"
                className="border border-gray-400 rounded-md px-4 py-2 mt-4 hover:text-white hover:bg-primary"
              >
                Book a call here
              </a>
            </div>
          </div>
        );
      case 'support-email':
        return <SupportEmail />;
      default:
        return null;
    }
  };

  const handleOutsideClick = () => {
    // Only toggle state and therefore re-render if any drop-downs are open
    if (state.updates || state.support || state.profile) {
      toggleState({
        updates: false,
        support: false,
        profile: false,
      });
    }
  };

  const useOutsideClick = (callback: VoidFunction) => {
    const ref = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [ref, callback]);

    return ref;
  };

  const dropdownRef = useOutsideClick(handleOutsideClick);

  return (
    <nav className="flex flex-col md:flex-row justify-between mt-11 mr-16 mb-8">
      <div className="md:self-end text-3xl text-primary">
        <Greeting account={account} />
      </div>
      <div className="flex w-fit divide-x py-4 px-6 bg-white rounded-md text-sm">
        {/* Updates */}
        <div className="flex flex-col justify-center">
          <button
            type="button"
            ref={dropdownRef}
            className="flex lg:pl-5 pr-8 items-center"
            onClick={(event) => {
              event.stopPropagation();
              toggleState({
                updates: !state.updates,
                support: false,
                profile: false,
              });
            }}
          >
            Updates
            <HiOutlineChevronDown className="ml-3" />
          </button>

          {/* Updates Dropdown */}
          <div className="relative w-0 h-0" onClick={(event) => { event.stopPropagation(); }} aria-hidden>
            <div
              className={`
                absolute z-10 top-4 origin-top-left rounded-md p-6
                bg-white shadow-sm ring-1 ring-black ring-opacity-5 
                focus:outline-none ${state.updates ? 'block' : 'hidden'}
              `}
              role="menu"
            >
              <div className="px-2">
                <div>Nothing Here!</div>
              </div>
            </div>
          </div>
        </div>

        {/* Support */}
        <div className="flex flex-col justify-center">
          <button
            type="button"
            ref={dropdownRef}
            className="flex pl-5 pr-8 items-center"
            onClick={(event) => {
              event.stopPropagation();
              toggleState({
                updates: false,
                support: !state.support,
                profile: false,
              });
            }}
          >
            Support
            <HiOutlineChevronDown className="ml-3" />
          </button>

          {/* Support Dropdown */}
          <div className="relative w-0 h-0" onClick={(event) => { event.stopPropagation(); }} aria-hidden>
            <div
              className={`
                  absolute z-10 top-4 w-[300px] origin-top-left rounded-md
                  bg-white shadow-sm ring-1 ring-black ring-opacity-5 
                  focus:outline-none ${state.support ? 'visible' : 'hidden'}
                `}
              role="menu"
            >
              <div className="flex border -mb-px pt-4 text-xs">
                <button
                  type="button"
                  className={
                    page === 'support-call'
                      ? 'w-full py-2 px-6 text-black rounded-t-lg border-b-2 border-black active group'
                      : 'w-full py-2 px-6 rounded-t-lg border-b-2 border-transparent hover:border-black hover:text-black group'
                  }
                  onClick={() => {
                    setPage('support-call');
                  }}
                >
                  Schedule a Call
                </button>
                <button
                  type="button"
                  className={
                    page === 'support-email'
                      ? 'w-full py-2 px-6 text-black rounded-t-lg border-b-2 border-black active group'
                      : 'w-full py-2 px-6 rounded-t-lg border-b-2 border-transparent hover:border-black hover:text-black group'
                  }
                  onClick={() => {
                    setPage('support-email');
                  }}
                >
                  Email Support
                </button>
              </div>
              {/* <div className="border mt-2 mb-4" /> */}
              {renderSupportDropdown()}
            </div>
          </div>
        </div>

        {/* Profile */}
        <div className="flex flex-col justify-center">

          <button
            type="button"
            ref={dropdownRef}
            className="flex pl-5 lg:pr-8 items-center"
            onClick={(event) => {
              event.stopPropagation();
              toggleState({
                updates: false,
                support: false,
                profile: !state.profile,
              });
            }}
          >
            <img className="rounded-full border h-10 w-10 md:mr-3.5" src={user?.picture} alt="user avatar" />
            <div className="hidden lg:block text-left">
              <div>{`${account?.first_name} ${account?.last_name}`}</div>
              <div className="text-gray-500 text-xs">{account?.company?.company_name}</div>
            </div>
            <HiOutlineChevronDown className="hidden lg:block lg:ml-3" />

          </button>

          {/* Profile Dropdown */}
          <div className="relative w-0 h-0" onClick={(event) => { event.stopPropagation(); }} aria-hidden>
            <div
              className={`
                absolute z-10 top-4 rounded-md p-6 min-w-[260px]
                bg-white shadow-sm ring-1 ring-black ring-opacity-5 
                focus:outline-none ${state.profile ? 'visible' : 'hidden'}
              `}
              role="menu"
            >
              <div className="px-2">
                <div className="font-semibold">
                  {`${account?.first_name} ${account?.last_name}`}
                </div>
                <div className="text-gray-500 text-sm">{account?.company_email}</div>
              </div>
              <div className="border mt-2 mb-4" />
              <Link
                to="/settings"
                className="block w-full text-gray-400 hover:bg-gray-100 hover:text-black px-4 py-2 text-left"
                onClick={() => toggleState({
                  updates: false,
                  support: false,
                  profile: false,
                })}
              >
                <BsFillGearFill className="h-5 w-5 inline-block mr-4" />
                Settings
              </Link>
              <button type="submit" role="menuitem" className="w-full text-gray-400 hover:bg-gray-100 hover:text-black px-4 py-2 text-left" onClick={() => logout({ returnTo: window.location.origin })}>
                <svg className="h-5 w-5 inline-block mr-4" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z" />
                  <path d="M4.56141 11.25L6.63141 9.18C6.78141 9.03 6.85141 8.84 6.85141 8.65C6.85141 8.46 6.78141 8.26 6.63141 8.12C6.49027 7.98052 6.29984 7.9023 6.10141 7.9023C5.90298 7.9023 5.71254 7.98052 5.57141 8.12L2.22141 11.47C1.93141 11.76 1.93141 12.24 2.22141 12.53L5.57141 15.88C5.86141 16.17 6.34141 16.17 6.63141 15.88C6.92141 15.59 6.92141 15.11 6.63141 14.82L4.56141 12.75H9.00141V11.25H4.56141Z" />
                </svg>
                Sign out
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Topbar;
