import React, { RefObject, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import tempData from './tempData.json';

function Overview() {
  const [timeSelection, setTimeSelection] = React.useState('three');
  const apiVolumeChart = useRef<SVGSVGElement>(null);
  const successActivationChart = useRef<SVGSVGElement>(null);
  const providerUsageChart = useRef<SVGSVGElement>(null);

  const displaySelection = () => {
    switch (timeSelection) {
      case 'three':
        return `${moment().subtract(3, 'months').add(1, 'days').format('MMM DD')} - ${moment().format('MMM DD')}`;

      case 'quarter':
        return `${moment().subtract(2, 'quarters').add(1, 'days').format('MMM DD')} - ${moment().subtract(1, 'quarters').format('MMM DD')}`;

      case 'year':
        return `${moment().subtract(1, 'year').add(1, 'days').format('MMM YYYY')} - ${moment().format('MMM YYYY')}`;

      default:
        return `Start - ${moment().format('MMM DD')}`;
    }
  };

  const generateChart = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    refTarget: RefObject<SVGSVGElement>,
    target: string,
  ) => {
    /* Clear any existing charts */
    d3.select(refTarget.current).selectChildren().remove();

    const margin = {
      top: 30, right: 0, bottom: 30, left: 0,
    };
    const width = parseInt(d3.select(target).style('width'), 10) - margin.left - margin.right;
    const height = parseInt(d3.select(target).style('height'), 10) - margin.top - margin.bottom;

    // Set up chart
    const svg = d3.select(refTarget.current)
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const xMin: Date = d3
      .min(data, (v: { date: Date, call_count: number; }) => new Date(v.date)) ?? new Date();
    const xMax: Date = d3
      .max(data, (v: { date: Date, call_count: number; }) => new Date(v.date)) ?? new Date();

    const x = d3.scaleTime()
      .domain([xMin, xMax])
      .range([0, width - margin.left - margin.right]);

    const max = d3
      .max(data, (d: { date: Date, call_count: number; }) => d.call_count) ?? 0;
    const y = d3.scaleLinear()
      .domain([-max, max])
      .range([height, 0]);

    // Add Line and Gradient!!!!
    svg.append('linearGradient')
      .attr('id', 'area-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%')
      .selectAll('stop')
      .data([
        { offset: '0%', color: 'rgba(17, 58, 84, 0.25)' },
        // add additional steps as needed for gradient.
        { offset: '65%', color: 'transparent' },
      ])
      .enter()
      .append('stop')
      .attr('offset', (d) => d.offset)
      .attr('stop-color', (d) => d.color);

    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#113A54')
      .attr('stroke-width', 1)
      .attr('d', d3.line()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .x((d: any) => x(new Date(d.date)))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .y((d: any) => y(d.call_count)));

    const area = d3.area()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .x((d: any) => x(new Date(d.date)))
      .y0(height)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .y1((d: any) => y(d.call_count));

    svg.append('path')
      .datum(data)
      .attr('class', 'area')
      .attr('d', area);
  };

  useEffect(() => {
    generateChart(tempData, apiVolumeChart, '#overview-api');
    generateChart(tempData, successActivationChart, '#overview-success-activation');
    generateChart(tempData, providerUsageChart, '#overview-provider-usage');
  });

  return (
    <div id="overview">
      <h1 className="text-2xl mb-4">
        Overview
      </h1>
      <div className="bg-white p-7 rounded-lg">
        <div className="grid grid-cols-1 gap-7 mx-6">
          <div className="flex space-x-8">
            <div className="flex w-fit items-center border-gray-200 border rounded-md divide-x-2">
              <select
                id="dates"
                name="dates"
                onChange={(e) => setTimeSelection(e.target.value)}
                className="bg-gray-100 border-none rounded-l-md h-full"
              >
                <option value="three">Last 3 months</option>
                <option value="quarter">Last Quarter</option>
                <option value="year">Last Year</option>
                <option value="all">All Time</option>
              </select>
              <div className="flex items-center bg-gray-100 p-2 rounded-r-md">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.8337 1.89581H1.16699C0.927826 1.89581 0.729492 1.69748 0.729492 1.45831C0.729492 1.21915 0.927826 1.02081 1.16699 1.02081H12.8337C13.0728 1.02081 13.2712 1.21915 13.2712 1.45831C13.2712 1.69748 13.0728 1.89581 12.8337 1.89581Z" fill="#333333" />
                  <path d="M2.1416 1.45831V8.44081C2.1416 9.01248 2.40993 9.55498 2.87077 9.89915L5.90993 12.1741C6.55743 12.6583 7.44993 12.6583 8.09743 12.1741L11.1366 9.89915C11.5974 9.55498 11.8658 9.01248 11.8658 8.44081V1.45831H2.1416ZM9.3341 8.02081H4.66743C4.42827 8.02081 4.22993 7.82248 4.22993 7.58331C4.22993 7.34415 4.42827 7.14581 4.66743 7.14581H9.3341C9.57327 7.14581 9.7716 7.34415 9.7716 7.58331C9.7716 7.82248 9.57327 8.02081 9.3341 8.02081ZM9.3341 5.10415H4.66743C4.42827 5.10415 4.22993 4.90581 4.22993 4.66665C4.22993 4.42748 4.42827 4.22915 4.66743 4.22915H9.3341C9.57327 4.22915 9.7716 4.42748 9.7716 4.66665C9.7716 4.90581 9.57327 5.10415 9.3341 5.10415Z" fill="#333333" />
                </svg>
                <span className="ml-2">
                  {displaySelection()}
                </span>
              </div>
            </div>
            <div className="space-x-4">
              <span>compared to</span>
              <select name="compared" id="compared" className="bg-gray-100 border-gray-200 rounded-md">
                <option value="three">Previous Period</option>
                <option value="all">All Time</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 2xl:grid-cols-3 mb-8">
            <div className="bg-[#FAEDED] w-full">
              <div className="pt-6 px-6">
                <div className="flex justify-between">
                  <div className="truncate">API Call Volume</div>
                  <div className="rounded-md bg-[#F7AD5C] text-white px-[14px] py-1">0.00%</div>
                </div>
                <div className="flex justify-between mt-3 text-2xl">
                  <div>$0.00</div>
                  <div className="text-gray-400">$0.00</div>
                </div>
                <div id="overview-api">
                  <svg ref={apiVolumeChart} />
                </div>
              </div>
              <div className="flex justify-between mx-[14px] mb-4">
                <div>Apr 21</div>
                <div>Today</div>
              </div>
            </div>

            <div className="bg-[#FAF6ED] w-full">
              <div className="pt-6 px-6">
                <div className="flex justify-between">
                  <div className="truncate">Successful Activation</div>
                  <div className="rounded-md bg-[#F7AD5C] text-white px-[14px] py-1">0.00%</div>
                </div>
                <div className="flex justify-between mt-3 text-2xl">
                  <div>$0.00</div>
                  <div className="text-gray-400">$0.00</div>
                </div>
                <div id="overview-success-activation">
                  <svg ref={successActivationChart} />
                </div>
              </div>
              <div className="flex justify-between mx-[14px] mb-4">
                <div>Apr 21</div>
                <div>Today</div>
              </div>
            </div>

            <div className="bg-[#EDFAF5] w-full">
              <div className="pt-6 px-6">
                <div className="flex justify-between">
                  <div className="truncate">Usage Per Service Provider</div>
                  <div className="rounded-md bg-[#F7AD5C] text-white px-[14px] py-1">0.00%</div>
                </div>
                <div className="flex justify-between mt-3 text-2xl">
                  <div>$0.00</div>
                  <div className="text-gray-400">$0.00</div>
                </div>
                <div id="overview-provider-usage">
                  <svg ref={providerUsageChart} />
                </div>
              </div>
              <div className="flex justify-between mx-[14px] mb-4">
                <div>Apr 21</div>
                <div>Today</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Overview;
