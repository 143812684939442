import React from 'react';
import Report from './Report';
import Overview from './Overview';

function Home() {
  return (
    <div className="space-y-8">
      <Report />
      <Overview />
    </div>
  );
}
export default Home;
