import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Account } from '../common/types';

interface Values {
  firstName: string;
  lastName: string;
  companyName: string;
  acceptTerms: boolean;
  signUpAs: Array<string>;
}

function Onboarding(
  { setAccount }: { setAccount: React.Dispatch<React.SetStateAction<Account | undefined>> },
) {
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const signUpAsSchema = Yup.string().oneOf(['eMSP', 'CPO'], 'Array has unknown value');
  const OnboardingSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(100, 'Too Long!')
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .max(100, 'Too Long!')
      .required('Last name is required'),
    companyName: Yup.string()
      .trim()
      .max(100, 'Too Long!')
      .required('Company name is required'),
    signUpAs: Yup.array(Yup.string())
      .of(signUpAsSchema)
      .min(1, 'Choose at least 1 type'),
    acceptTerms: Yup.boolean()
      .oneOf([true], 'Please confirm that you accept our terms'),
  });

  const saveOnboardingData = async (values: Values) => {
    // Sanitize inputs
    const firstName = values.firstName.replace(/\s+/g, ' ').trim();
    const lastName = values.lastName.replace(/\s+/g, ' ').trim();
    const companyName = values.companyName.replace(/\s+/g, ' ').trim();

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
        scope: 'openid profile email',
      });

      const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.aeoncharge.com/api'}/onboarding-submission`;
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          auth0_sub: user?.sub,
          first_name: firstName,
          last_name: lastName,
          company_name: companyName,
          type_cpo: values.signUpAs.includes('CPO'),
          type_emsp: values.signUpAs.includes('eMSP'),
          onboarding_complete: true,
        }),
      };

      fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((response) => {
          if (!response) return;
          setAccount(response);
        });
    } catch (error) {
      // @TODO - Make error display on screen
      // console.error(error);
    }
  };

  return (
    <div className="min-h-screen w-full bg-primary">
      <section className="pb-20 authBackground h-full w-full">
        <div className="flex justify-between text-white underline p-10">
          <a href="https://www.aeoncharge.com">Back to Website</a>
          <button
            type="button"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Logout
          </button>
        </div>
        <div className="flex items-center justify-center">
          <div className="bg-white shadow-lg rounded-md h-fit sm:w-fit">
            <div className="p-12 mx-6">
              <div className="text-center">
                <h4 className="text-2xl font-light mt-1 mb-4">Onboard with AeonCharge</h4>
              </div>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  companyName: '',
                  acceptTerms: false,
                  signUpAs: [],
                }}
                validationSchema={OnboardingSchema}
                onSubmit={saveOnboardingData}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="w-fit mx-auto mb-4">
                      <label htmlFor="firstName" className="text-sm text-gray-600">First Name</label>
                      <Field
                        id="firstName"
                        name="firstName"
                        placeholder="John"
                        type="text"
                        className="block w-full sm:w-[360px] text-base font-normal text-gray-700 placeholder-gray-400 border-gray-300 rounded transition ease-in-out mt-1 focus:text-gray-700 focus:border-blue-600"
                      />
                      {
                        errors.firstName && touched.firstName
                          ? (<div className="text-sm text-red-400">{errors.firstName}</div>) : null
                      }
                    </div>

                    <div className="w-fit mx-auto mb-4">
                      <label htmlFor="lastName" className="text-sm text-gray-600">Last Name</label>
                      <Field
                        id="lastName"
                        name="lastName"
                        placeholder="Doe"
                        type="text"
                        className="block w-full sm:w-[360px] text-base font-normal text-gray-700 placeholder-gray-400 border-gray-300 rounded transition ease-in-out mt-1 focus:text-gray-700 focus:border-blue-600"
                      />
                      {
                        errors.lastName && touched.lastName
                          ? (<div className="text-sm text-red-400">{errors.lastName}</div>) : null
                      }
                    </div>

                    <div className="w-fit mx-auto mb-4">
                      <label htmlFor="companyName" className="text-sm text-gray-600">Company Name</label>
                      <Field
                        id="companyName"
                        name="companyName"
                        placeholder="Enter company name"
                        type="text"
                        className="block w-full sm:w-[360px] text-base font-normal text-gray-700 placeholder-gray-400 border-gray-300 rounded transition ease-in-out mt-1 focus:text-gray-700 focus:border-blue-600"
                      />
                      {
                        errors.companyName && touched.companyName
                          ? (<div className="text-sm text-red-400">{errors.companyName}</div>) : null
                      }
                    </div>

                    <div className="w-fit mx-auto mb-4">
                      <div className="sm:w-[360px]">

                        <div className="text-sm text-gray-600">Sign Up As</div>
                        <div role="group" aria-labelledby="signUpAs-checkbox-group" className="flex mt-3">
                          <div className="flex justify-between items-center mr-4">
                            <Field
                              type="checkbox"
                              id="eMSP"
                              name="signUpAs"
                              value="eMSP"
                              className="
                                border-gray-300 border-2
                                rounded-sm
                                focus:border-gray-300
                              "
                            />
                            <label htmlFor="eMSP" className="ml-2 text-sm">
                              eMSP
                            </label>
                          </div>
                          <div className="flex justify-between items-center mr-4">
                            <Field
                              type="checkbox"
                              id="CPO"
                              name="signUpAs"
                              value="CPO"
                              className="
                                border-gray-300 border-2
                                rounded-sm
                                focus:border-gray-300
                              "
                            />
                            <label htmlFor="CPO" className="ml-2 text-sm">
                              CPO
                            </label>
                          </div>
                        </div>
                      </div>
                      {
                        errors.signUpAs && touched.signUpAs
                          ? (<div className="text-sm text-red-400">{errors.signUpAs}</div>) : null
                      }
                    </div>

                    <div className="w-fit mx-auto">
                      <div className="sm:w-[360px] mb-8 ">
                        <label htmlFor="acceptTerms" className="inline-flex items-center mt-4">
                          <Field
                            id="acceptTerms"
                            type="checkbox"
                            name="acceptTerms"
                            className="
                              border-gray-300 border-2
                              rounded-sm
                              focus:border-gray-300
                            "
                          />
                          <span className="ml-2 text-sm">
                            Agree to terms and conditions
                          </span>
                        </label>
                        {
                          errors.acceptTerms && touched.acceptTerms
                            ? (<div className="text-sm text-red-400">{errors.acceptTerms}</div>) : null
                        }
                      </div>
                      <button
                        type="submit"
                        className="text-lg bg-primary w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}
export default Onboarding;
