import React from 'react';

function Transactions() {
  return (
    <div className="rounded-md bg-white p-8">
      <table className="w-full">
        <thead>
          <tr>
            <th className="p-4 text-left">Who</th>
            <th className="p-4 text-left">$</th>
            <th className="p-4 text-left">Total Time</th>
            <th className="p-4 text-left">Charging Network</th>
            <th className="p-4 text-left">kWh</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-4">John Doe</td>
            <td className="p-4">$ 25.00</td>
            <td className="p-4">2 hrs</td>
            <td className="p-4">Charge Point</td>
            <td className="p-4">6.6 kWh</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Transactions;
