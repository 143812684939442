import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { Auth0ProviderWithRedirectCallbackProps, ProtectedRouteProps } from '../common/types';

export function ProtectedRoute({ component, args }: ProtectedRouteProps) {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
}

export function Auth0ProviderWithRedirectCallback(
  { children, ...props }: Auth0ProviderWithRedirectCallbackProps,
) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={props.domain}
      clientId={props.clientId}
      redirectUri={props.redirectUri}
      audience={props.audience}
      scope={props.scope}
    >
      {children}
    </Auth0Provider>
  );
}
