import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Auth0ProviderWithRedirectCallback, ProtectedRoute } from './Auth0ProviderWithRedirectCallback';

import Home from '../pages/home';
import Team from '../pages/team';
import AccountConnection from '../pages/accountConnection';
import EndpointDetails from '../pages/endpointDetails';
import Transactions from '../pages/transactions';
import Billing from '../pages/billing';
import Settings from '../pages/settings';
import Error from '../pages/Error';
import ProtectedLayout from './ProtectedLayout';
import Spinner from './Spinner';
import { AccountProvider } from '../common/AccountContext';

function App() {
  const renderSpinner = () => <Spinner />;

  return (
    <Auth0ProviderWithRedirectCallback
      domain={process.env.REACT_APP_AUTH0_DOMAIN || 'dev-uq3-g072.us.auth0.com'}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || 'RRBHWpiVjKLotn0BZaTnWoZ9vFZb992q'}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com'}
      scope="openid profile"
    >
      <AccountProvider>
        <Routes>
          <Route
            path="/"
            element={(
              <ProtectedRoute
                component={ProtectedLayout}
                args={{ onRedirecting: renderSpinner }}
              />
            )}
          >
            <Route path="" element={<Home />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="billing" element={<Billing />} />
            <Route path="team" element={<Team />} />
            <Route path="accountConnection" element={<AccountConnection />} />
            <Route path="endpointDetails" element={<EndpointDetails />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route
            path="*"
            element={<Error />}
          />
        </Routes>
      </AccountProvider>
    </Auth0ProviderWithRedirectCallback>
  );
}

export default App;
