import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import Welcome from './Welcome';

function Report() {
  const refTarget = useRef<HTMLDivElement>(null);
  const [datePicker, setDatePicker] = useState(moment().format('L'));

  useEffect(() => {
    /* Clear any existing charts */
    d3.select(refTarget.current).selectAll('svg').remove();

    /* Setup Chart */
    const WIDTH = parseInt(d3.select('#today').style('width'), 10);
    const HEIGHT = 140;
    const MARGIN = {
      top: 10, right: 10, bottom: 20, left: 30,
    };
    const INNER_WIDTH = WIDTH;
    const INNER_HEIGHT = HEIGHT - MARGIN.top - MARGIN.bottom;
    const svg = d3.select(refTarget.current)
      .append('svg')
      .attr('viewBox', `0 0 ${WIDTH} ${HEIGHT}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(0,${MARGIN.top})`);

    const x = d3.scaleLinear().domain([0, 1]).range([0, INNER_WIDTH]);
    const xAxisGrid = d3.axisBottom(x).tickSize(-INNER_HEIGHT).tickFormat(() => '').ticks(25);

    const y = d3.scaleLinear().domain([-1, 1]).range([INNER_HEIGHT, 0]);

    /* Create grids. */
    svg.append('g')
      .attr('class', 'text-gray-300')
      .attr('transform', `translate(0,${INNER_HEIGHT})`)
      .call(xAxisGrid);

    /* Create middle guide line */
    const midPoints: Iterable<[number, number]> = [[0, 0], [20, 0]];
    svg.append('path')
      .datum(midPoints)
      .attr('fill', 'none')
      .attr('class', 'stroke-gray-300')
      .attr('stroke-width', 2)
      .attr('d', d3.line()
        .x((d) => x(d[0]))
        .y((d) => y(d[1])));

    /* Draw circle marker for data line */
    svg
      .append('defs')
      .append('marker')
      .attr('id', 'dot')
      .attr('viewBox', [0, 0, 20, 20])
      .attr('refX', 10)
      .attr('refY', 10)
      .attr('markerWidth', 2.5)
      .attr('markerHeight', 2.5)
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 10)
      .attr('r', 10)
      .style('fill', 'primary');

    /* Plot the data line */
    const counts: Iterable<[number, number]> = [[0, 0], [1 / 20, 0], [2 / 20, 0], [2.5 / 20, 0]];
    svg.append('path')
      .datum(counts)
      .attr('fill', 'none')
      .attr('class', 'stroke-primary')
      .attr('stroke-width', 3)
      .attr('marker-end', 'url(#dot)')
      .attr('d', d3.line()
        .x((d) => x(d[0]))
        .y((d) => y(d[1])));
  });

  return (
    <div className="grid grid-cols-1 space-y-4 2xl:grid-cols-4 2xl:space-x-8 2xl:space-y-0">
      <div className="col-span-3">
        <h1 className="text-2xl mb-4">Report</h1>
        <div className="bg-white rounded-lg p-7">
          <div className="flex flex-col space-y-4 mx-6">
            <div id="pickers" className="flex space-x-4">
              <div className="flex flex-col">
                <select name="chart-type" className="text-xs bg-gray-100 border-gray-300 rounded-md">
                  <option value="api">API Call Volume</option>
                  <option value="success">Successful Activation</option>
                  <option value="service">Usage Per Service Provider</option>
                </select>
                <span className="m-2">0</span>
              </div>
              <div className="flex flex-col">
                <input
                  type="date"
                  id="time-period"
                  name="time-period"
                  className="text-xs bg-gray-100 border-gray-300 rounded-md"
                  value={datePicker}
                  onChange={(e) => setDatePicker(e.target.value)}
                />
                <span className="m-2">0</span>
              </div>
            </div>
            <div id="today">
              <div ref={refTarget} />
              <div className="flex justify-between text-xs">
                <div>12:00 AM</div>
                <div>11:59 PM</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Welcome />
    </div>
  );
}
export default Report;
