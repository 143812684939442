import React from 'react';
import { BsRocket, BsBoxSeam, BsQuestion } from 'react-icons/bs';

function Welcome() {
  return (
    <div className="flex flex-col h-full">
      <h1 className="text-2xl mb-4">Welcome</h1>
      <div className="flex flex-col h-full justify-center items-center bg-white rounded-lg py-6">
        <div className="flex flex-col space-y-6">
          <div className="flex items-center">
            <div className="h-full inline-block border-2 p-4 mr-8">
              <BsRocket />
            </div>
            <h3 className="text-xl">
              Getting Started
            </h3>
          </div>
          <div className="flex items-center">
            <div className="h-full inline-block border-2 p-4 mr-8">
              <BsBoxSeam />
            </div>
            <h3 className="text-xl">
              Products
            </h3>
          </div>
          <div className="flex items-center">
            <div className="h-full inline-block border-2 p-4 mr-8">
              <BsQuestion />
            </div>
            <h3 className="text-xl">
              Frequently Asked
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
