import React from 'react';
import { useLocation } from 'react-router-dom';
import { Account } from '../common/types';

function Greeting({ account }: { account: Account; }) {
  const { pathname } = useLocation();

  switch (pathname) {
    case '/':
      return (
        <h1>
          {`Hello ${account?.first_name ?? 'There!'}`}
        </h1>
      );
    case '/transactions':
      return (
        <h1>Transactions</h1>
      );
    case '/billing':
      return (
        <h1>Billing</h1>
      );
    case '/invoices':
      return (
        <h1>Invoices</h1>
      );
    case '/team':
      return (
        <h1>Team</h1>
      );

    case '/accountConnection':
      return (
        <h1>API Access</h1>
      );
    case '/endpointDetails':
      return (
        <h1>Endpoints</h1>
      );
    case '/settings':
      return (
        <h1>Settings</h1>
      );

    default:
      return (
        <div>Greeting</div>
      );
  }
}
export default Greeting;
