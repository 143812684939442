import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { Account } from '../../common/types';
import { useAccount } from '../../common/AccountContext';

function Company({ account }: { account: Account | undefined; }) {
  const { getAccessTokenSilently } = useAuth0();
  const { setAccount } = useAccount();
  const [companyName, setCompanyName] = React.useState(account?.company?.company_name || '');
  const [editable, setEditable] = React.useState({
    name: false,
    address: false,
  });

  const successToast = (response: Account) => {
    setAccount(response);
    return <b>Changes saved!</b>;
  };

  const errorToast = () => {
    setCompanyName(account?.company?.company_name || '');
    return <b>Could not save.</b>;
  };

  const updateCompany = async (value: string) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
      scope: 'openid profile email',
    });

    const requestBody = {
      auth0_sub: account?.auth0_sub,
      company_id: account?.company?.id,
      company_name: value,
    };

    const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.aeoncharge.com/api'}/update-account`;
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    toast.promise(
      fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        }),
      {
        loading: 'Saving...',
        success: successToast,
        error: errorToast,
      },
    );
  };

  const handleEdit = async () => {
    setEditable({ ...editable, name: !editable.name });
    if (companyName !== account?.company?.company_name) {
      // Display Confirmation modal?

      // Update company name to backend
      await updateCompany(companyName);
    }
  };

  const handleEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setCompanyName(account?.company?.company_name || '');
      setEditable({ ...editable, name: false });
    }

    if (e.key === 'Enter') {
      await handleEdit();
    }
  };

  return (
    <div className="bg-white rounded-md w-full h-full px-10 py-14 space-y-10">
      <div className="border divide-y rounded-sm">
        <h1 className="font-semibold px-8 py-5">Company Information</h1>
        <div className="flex justify-between px-8 py-5">
          <div className="text-gray-400">Company name</div>
          <div className="flex">
            <input
              style={{ width: `${companyName.length + 5}ch` }}
              type="text"
              name="company-name"
              value={companyName}
              onKeyDown={handleEnter}
              onChange={(e) => setCompanyName(e.target.value)}
              disabled={!editable.name}
              className={`text-right mr-14 ${editable.name ? '' : 'border-none border-transparent focus:border-transparent focus:ring-0'}`}
            />
            <button name="company-name" type="button" aria-label="company-name" onClick={handleEdit}>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                  <path d="M16.318 16.866H1.13272C0.786834 16.866 0.5 16.5791 0.5 16.2332C0.5 15.8874 0.786834 15.6005 1.13272 15.6005H16.318C16.6639 15.6005 16.9508 15.8874 16.9508 16.2332C16.9508 16.5791 16.6639 16.866 16.318 16.866Z" fill="#333333" />
                  <path d="M14.6478 1.24335C13.0111 -0.393292 11.4082 -0.435473 9.72941 1.24335L8.70862 2.26414C8.62426 2.3485 8.59051 2.48348 8.62426 2.60159C8.94691 3.70875 9.54355 4.71667 10.359 5.53212C11.1744 6.34757 12.1824 6.94421 13.2895 7.26686C13.3493 7.28644 13.4134 7.28891 13.4745 7.274C13.5356 7.2591 13.5914 7.2274 13.6354 7.1825L14.6478 6.1617C15.483 5.33495 15.8879 4.5335 15.8879 3.72362C15.8963 2.88842 15.4914 2.07854 14.6478 1.24335Z" fill="#333333" />
                  <path d="M11.7709 8.03254C11.5262 7.91443 11.29 7.79633 11.0622 7.66134C10.8772 7.55112 10.6971 7.4329 10.5223 7.30702C10.3789 7.21422 10.2102 7.07924 10.0499 6.94426C9.99824 6.90662 9.95023 6.86426 9.90645 6.81772C9.62806 6.5815 9.31591 6.27779 9.03752 5.94034C9.01221 5.92347 8.97003 5.86441 8.91097 5.78849C8.82661 5.68725 8.68319 5.51853 8.55665 5.32449C8.43639 5.16582 8.32649 4.99956 8.22763 4.82675C8.09265 4.59897 7.97454 4.37119 7.85644 4.13498C7.83887 4.0972 7.82172 4.05923 7.80497 4.02109C7.68012 3.74016 7.31314 3.65833 7.09633 3.87514L2.26318 8.70829C2.15351 8.81796 2.05227 9.02887 2.02696 9.17228L1.5714 12.4034C1.48704 12.9771 1.64733 13.517 2.00165 13.8797C2.30536 14.175 2.72717 14.3353 3.18273 14.3353C3.28397 14.3353 3.3852 14.3269 3.48644 14.31L6.72597 13.8544C6.87783 13.8291 7.08873 13.7279 7.18997 13.6182L12.0172 8.79181C12.2366 8.57246 12.153 8.19705 11.8687 8.07472C11.8361 8.06074 11.8035 8.04668 11.7709 8.03254Z" fill="#333333" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Company;
