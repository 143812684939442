import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
} from 'formik';

interface MyFormValues {
  name: string;
  email: string;
  body: string;
}

function SupportEmail() {
  const { getAccessTokenSilently } = useAuth0();
  const [success, setSuccess] = React.useState(false);

  const initialValues: MyFormValues = {
    name: '',
    email: '',
    body: '',
  };

  const submitSupportEmail = async (
    values: MyFormValues,
    actions: FormikHelpers<MyFormValues>,
  ) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
        scope: 'openid profile email',
      });

      const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.aeoncharge.com/api'}/support-email`;
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      };

      fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((response) => {
          if (!response) return;
          setSuccess(response);
          actions.setSubmitting(false);
        });
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  if (success) {
    return (
      <div className="p-4">
        <h1 className="text-lg mb-4">
          Thank you for contacting us!
        </h1>
        <p>
          We will get back to you as soon as possible.
        </p>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitSupportEmail}
    >
      <Form>
        <div className="p-4 space-y-2">
          <Field
            className="w-full border-none bg-gray-100 rounded-md p-3 text-sm placeholder-gray-400"
            id="name"
            type="text"
            name="name"
            placeholder="Your Name"
          />
          <Field
            className="w-full border-none bg-gray-100 rounded-md p-3 text-sm placeholder-gray-400"
            id="email"
            type="email"
            name="email"
            placeholder="Your Email"
          />
          <Field
            as="textarea"
            className="w-full border-none bg-gray-100 rounded-md p-3 text-sm placeholder-gray-400"
            id="body"
            type="text"
            name="body"
            cols={30}
            rows={10}
            placeholder="Write your description here..."
          />
          <div className="flex justify-end">
            <button
              type="submit"
              className="mb-4 mt-3 bg-primary text-white
              rounded-md py-2 px-4 hover:text-white hover:bg-black"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
export default SupportEmail;
