import React from 'react';
import { Account, AccountProviderProps, SetAccount } from './types';

const AccountContext = React.createContext<
{
  account?: Account;
  setAccount: SetAccount
} | undefined
>(undefined);

export function AccountProvider({ children }: AccountProviderProps) {
  const [account, setAccount] = React.useState<Account>();
  const value = React.useMemo(() => ({ account, setAccount }), [account]);
  return (
    <AccountContext.Provider value={value}>
      { children }
    </AccountContext.Provider>
  );
}

export function useAccount() {
  const context = React.useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
}
