import React, { useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [payments, togglePayments] = useState(
    pathname === '/transactions' || pathname === '/billing',
  );
  const [integration, toggleIntegration] = useState(
    pathname === '/accountConnection' || pathname === '/endpointDetails',
  );

  return (
    <aside className={`flex-none min-h-screen py-6 shadow-sm relative duration-300 ${open ? 'w-80' : 'w-24'}`} aria-label="Sidebar">
      <ul className={`${open ? 'mx-6' : 'mx-4'} space-y-1`}>
        <li>
          <a href="/" className="flex items-center justify-center p-1 mb-10 rounded-lg text-base">
            <svg className={`${open && 'hidden'}`} id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
              <g id="Layer_1-2" data-name="Layer 1-2">
                <rect width="400" height="400" fill="#f9f9f9" opacity="0" strokeWidth="0" />
                <polygon points="372.71 110.77 260.51 199.98 148.33 289.22 148.33 199.98 148.33 110.77 260.51 110.77 372.71 110.77" fill="#c10d32" fillRule="evenodd" strokeWidth="0" />
                <polygon points="27.29 289.22 72.68 253.14 118.07 217.02 118.07 253.14 118.07 289.22 72.68 289.22 27.29 289.22" fill="#c10d32" fillRule="evenodd" strokeWidth="0" />
              </g>
            </svg>
            <img src="/logo.png" className={`${!open && 'hidden'}`} alt="AeonCharge Logo" />
          </a>
        </li>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'flex items-center p-5 rounded-lg text-base text-white bg-primary tooltip'
              : 'flex items-center p-5 rounded-lg text-base text-gray-500 hover:text-white hover:bg-primary tooltip')}
          >
            <div>
              <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.0402 6.82189L14.2802 2.79189C12.7102 1.69189 10.3002 1.75189 8.79023 2.92189L3.78023 6.83189C2.78023 7.61189 1.99023 9.21189 1.99023 10.4719V17.3719C1.99023 19.9219 4.06023 22.0019 6.61023 22.0019H17.3902C19.9402 22.0019 22.0102 19.9319 22.0102 17.3819V10.6019C22.0102 9.25189 21.1402 7.59189 20.0402 6.82189ZM12.7502 18.0019C12.7502 18.4119 12.4102 18.7519 12.0002 18.7519C11.5902 18.7519 11.2502 18.4119 11.2502 18.0019V15.0019C11.2502 14.5919 11.5902 14.2519 12.0002 14.2519C12.4102 14.2519 12.7502 14.5919 12.7502 15.0019V18.0019Z" /></svg>
              <span className={`${open ? 'hidden' : 'tooltiptext'}`}>Home</span>
            </div>
            <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>Home</span>
          </NavLink>
        </li>

        <li>
          <div className="tree-menu">
            <div
              tabIndex={0}
              role="button"
              className={`flex w-full items-center p-5 rounded-lg text-base tooltip ${pathname === '/billing' || pathname === '/transactions' ? 'bg-primary text-white' : 'text-gray-500 hover:text-white hover:bg-primary'}`}
              onKeyDown={(event) => {
                if (event.code === 'Enter') {
                  if (!open) {
                    setOpen(true);
                    togglePayments(true);
                  } else {
                    togglePayments(!payments);
                  }
                  navigate('/transactions');
                }
              }}
              onClick={() => {
                if (!open) {
                  setOpen(true);
                  togglePayments(true);
                } else {
                  togglePayments(!payments);
                }

                if (pathname !== '/transactions' && pathname !== '/billing') {
                  navigate('/transactions');
                }
              }}
            >
              <div>
                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z" />
                  <path d="M3.66992 2.5V14.47C3.66992 15.45 4.12992 16.38 4.91992 16.97L10.1299 20.87C11.2399 21.7 12.7699 21.7 13.8799 20.87L19.0899 16.97C19.8799 16.38 20.3399 15.45 20.3399 14.47V2.5H3.66992ZM15.9999 13.75H7.99992C7.58992 13.75 7.24992 13.41 7.24992 13C7.24992 12.59 7.58992 12.25 7.99992 12.25H15.9999C16.4099 12.25 16.7499 12.59 16.7499 13C16.7499 13.41 16.4099 13.75 15.9999 13.75ZM15.9999 8.75H7.99992C7.58992 8.75 7.24992 8.41 7.24992 8C7.24992 7.59 7.58992 7.25 7.99992 7.25H15.9999C16.4099 7.25 16.7499 7.59 16.7499 8C16.7499 8.41 16.4099 8.75 15.9999 8.75Z" />
                </svg>
                <span className={`${open ? 'hidden' : 'tooltiptext'}`}>Payments</span>
              </div>
              <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>Payments</span>
              <span><MdChevronRight className={`text-lg ${!open && 'hidden'}`} /></span>
            </div>
            <ul
              id="integration-submenu"
              className={`ml-5 ${(payments && open) ? 'block' : 'hidden'}`}
            >
              <li className="pt-2">
                <NavLink
                  to="/transactions"
                  className={({ isActive }) => (isActive ? 'flex items-center px-8 py-3 rounded-lg text-base text-black bg-gray-100'
                    : 'flex items-center px-8 py-3 rounded-lg text-base text-gray-400 hover:text-black hover:bg-gray-100')}
                >
                  <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>Transactions</span>
                </NavLink>
              </li>
              <li className="pt-2">
                <NavLink
                  to="/billing"
                  className={({ isActive }) => (isActive ? 'flex items-center px-8 py-3 rounded-lg text-base text-black bg-gray-100'
                    : 'flex items-center px-8 py-3 rounded-lg text-base text-gray-400 hover:text-black hover:bg-gray-100')}
                >
                  <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>Billing</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <NavLink
            to="/team"
            className={({ isActive }) => (isActive ? 'flex items-center p-5 rounded-lg text-base text-white bg-primary tooltip'
              : 'flex items-center p-5 rounded-lg text-base text-gray-500 hover:text-white hover:bg-primary tooltip')}
          >
            <div>
              <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.53 7.77C17.4604 7.76 17.3897 7.76 17.32 7.77C16.5732 7.74556 15.8652 7.43119 15.3462 6.89355C14.8273 6.3559 14.5381 5.63726 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.3179 5.63773 20.026 6.35553 19.5058 6.89258C18.9855 7.42963 18.2773 7.74413 17.53 7.77Z" />
                <path d="M20.7925 14.6999C19.6725 15.4499 18.1025 15.7299 16.6525 15.5399C17.0325 14.7199 17.2325 13.8099 17.2425 12.8499C17.2425 11.8499 17.0225 10.8999 16.6025 10.0699C18.0825 9.86991 19.6525 10.1499 20.7825 10.8999C22.3625 11.9399 22.3625 13.6499 20.7925 14.6999Z" />
                <path d="M6.44016 7.76988C6.51016 7.75988 6.58016 7.75988 6.65016 7.76988C7.39702 7.74545 8.105 7.43107 8.62397 6.89343C9.14295 6.35578 9.43212 5.63714 9.43016 4.88988C9.43016 4.51102 9.35553 4.13587 9.21055 3.78584C9.06556 3.43582 8.85306 3.11778 8.58516 2.84988C8.31726 2.58198 7.99922 2.36948 7.6492 2.22449C7.29917 2.07951 6.92402 2.00488 6.54516 2.00488C6.16629 2.00488 5.79114 2.07951 5.44111 2.22449C5.09109 2.36948 4.77305 2.58198 4.50515 2.84988C4.23726 3.11778 4.02475 3.43582 3.87976 3.78584C3.73478 4.13587 3.66016 4.51102 3.66016 4.88988C3.66016 6.44988 4.89016 7.71988 6.44016 7.76988Z" />
                <path d="M6.55109 12.8501C6.55109 13.8201 6.76109 14.7401 7.14109 15.5701C5.73109 15.7201 4.26109 15.4201 3.18109 14.7101C1.60109 13.6601 1.60109 11.9501 3.18109 10.9001C4.25109 10.1801 5.76109 9.8901 7.18109 10.0501C6.77109 10.8901 6.55109 11.8401 6.55109 12.8501Z" />
                <path d="M12.1198 15.87C12.0334 15.86 11.9462 15.86 11.8598 15.87C10.9707 15.8401 10.128 15.4655 9.51024 14.8253C8.89245 14.1851 8.54801 13.3297 8.54981 12.44C8.55981 10.54 10.0898 9 11.9998 9C13.8998 9 15.4398 10.54 15.4398 12.44C15.4393 13.3306 15.0927 14.1862 14.4733 14.8262C13.8538 15.4662 13.01 15.8404 12.1198 15.87Z" />
                <path d="M8.8698 17.9399C7.3598 18.9499 7.3598 20.6099 8.8698 21.6099C10.5898 22.7599 13.4098 22.7599 15.1298 21.6099C16.6398 20.5999 16.6398 18.9399 15.1298 17.9399C13.4198 16.7899 10.5998 16.7899 8.8698 17.9399Z" />
              </svg>
              <span className={`${open ? 'hidden' : 'tooltiptext'}`}>Team</span>
            </div>
            <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>Team</span>
          </NavLink>
        </li>
        <li>
          <div className="tree-menu">
            <div
              tabIndex={0}
              role="button"
              className={`flex w-full items-center p-5 rounded-lg text-base tooltip ${pathname === '/endpointDetails' || pathname === '/accountConnection' ? 'bg-primary text-white' : 'text-gray-500 hover:text-white hover:bg-primary'}`}
              onKeyDown={(event) => {
                if (event.code === 'Enter') {
                  if (!open) {
                    setOpen(true);
                    toggleIntegration(true);
                  } else {
                    toggleIntegration(!integration);
                  }
                  navigate('/accountConnection');
                }
              }}
              onClick={() => {
                if (!open) {
                  setOpen(true);
                  toggleIntegration(true);
                } else {
                  toggleIntegration(!integration);
                }

                if (pathname !== '/accountConnection' && pathname !== '/endpointDetails') {
                  navigate('/accountConnection');
                }
              }}
            >
              <div>
                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.6704 0H16.7704C14.5904 0 13.4404 1.15 13.4404 3.33V5.23C13.4404 7.41 14.5904 8.56 16.7704 8.56H18.6704C20.8504 8.56 22.0004 7.41 22.0004 5.23V3.33C22.0004 1.15 20.8504 0 18.6704 0Z" />
                  <path d="M7.24 11.4299H5.34C3.15 11.4299 2 12.5799 2 14.7599V16.6599C2 18.8499 3.15 19.9999 5.33 19.9999H7.23C9.41 19.9999 10.56 18.8499 10.56 16.6699V14.7699C10.57 12.5799 9.42 11.4299 7.24 11.4299Z" />
                  <path d="M6.29 8.58C6.85337 8.58 7.41123 8.46904 7.93171 8.25344C8.4522 8.03785 8.92513 7.72185 9.32349 7.32349C9.72185 6.92513 10.0379 6.4522 10.2534 5.93171C10.469 5.41123 10.58 4.85337 10.58 4.29C10.58 3.72663 10.469 3.16878 10.2534 2.64829C10.0379 2.1278 9.72185 1.65488 9.32349 1.25651C8.92513 0.858149 8.4522 0.542149 7.93171 0.326557C7.41123 0.110964 6.85337 -8.39488e-09 6.29 0C5.15222 1.69542e-08 4.06104 0.451981 3.25651 1.25651C2.45198 2.06104 2 3.15222 2 4.29C2 5.42778 2.45198 6.51896 3.25651 7.32349C4.06104 8.12802 5.15222 8.58 6.29 8.58Z" />
                  <path d="M17.7099 20.0021C18.8477 20.0021 19.9389 19.5501 20.7434 18.7456C21.5479 17.9411 21.9999 16.8499 21.9999 15.7121C21.9999 14.5743 21.5479 13.4832 20.7434 12.6786C19.9389 11.8741 18.8477 11.4221 17.7099 11.4221C16.5721 11.4221 15.481 11.8741 14.6764 12.6786C13.8719 13.4832 13.4199 14.5743 13.4199 15.7121C13.4199 16.8499 13.8719 17.9411 14.6764 18.7456C15.481 19.5501 16.5721 20.0021 17.7099 20.0021Z" />
                </svg>
                <span className={`${open ? 'hidden' : 'tooltiptext'}`}>Integration</span>
              </div>
              <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>Integration</span>
              <span><MdChevronRight className={`text-lg ${!open && 'hidden'}`} /></span>
            </div>
            <ul
              id="integration-submenu"
              className={`ml-5 ${(integration && open) ? 'block' : 'hidden'}`}
            >
              <li className="pt-2">
                <NavLink
                  to="/accountConnection"
                  className={({ isActive }) => (isActive ? 'flex items-center px-8 py-3 rounded-lg text-base text-black bg-gray-100'
                    : 'flex items-center px-8 py-3 rounded-lg text-base text-gray-400 hover:text-black hover:bg-gray-100')}
                >
                  <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>API Access</span>
                </NavLink>
              </li>
              <li className="pt-2">
                <NavLink
                  to="/endpointDetails"
                  className={({ isActive }) => (isActive ? 'flex items-center px-8 py-3 rounded-lg text-base text-black bg-gray-100'
                    : 'flex items-center px-8 py-3 rounded-lg text-base text-gray-400 hover:text-black hover:bg-gray-100')}
                >
                  <span className={`flex-1 ml-6 whitespace-nowrap ${!open && 'scale-0'}`}>EndPoints</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <MdChevronLeft
        tabIndex={0}
        className={`
          bg-white border-2 border-primarylight text-3xl 
          rounded-full absolute -right-3 cursor-pointer bottom-20
          ${!open && 'rotate-180'}
        `}
        onClick={() => setOpen(!open)}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            setOpen(!open);
          }
        }}
      />
    </aside>
  );
}
export default Sidebar;
