import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function WaitForApproval() {
  const { logout } = useAuth0();
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-2xl font-semibold pb-10">Thank you for signing up!</h1>
        <h2 className="text-xl font-semibold pb-10">Please Wait For Approval</h2>
        <p>AeonCharge team is currently reviewing your account and will approve you shortly.</p>
        <p className="pb-10">Thank you for your patience.</p>
        <div className="mb-10">
          <p>Urgent questions?</p>
          <a className="hover:underline" href="mailto:team@aeoncharge">team@aeoncharge.com</a>
        </div>
        <div>
          <button type="button" onClick={() => logout()} className="bg-primary hover:bg-SecondaryDark text-white font-bold py-2 px-4 rounded">Signout</button>
        </div>
      </div>
    </div>
  );
}
export default WaitForApproval;
