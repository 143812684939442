import React from 'react';
import Personal from './Personal';
import Company from './Company';
import { useAccount } from '../../common/AccountContext';

function Settings() {
  const { account } = useAccount();
  const [page, setPage] = React.useState('personal');

  const renderSubPage = () => {
    switch (page) {
      case 'personal':
        return <Personal account={account} />;
      case 'company':
        return <Company account={account} />;
      default:
        return <Personal account={account} />;
    }
  };

  return (
    <>
      <div className="border-b border-gray-400 mb-7">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-400">
          <li className="mr-2">
            <button
              type="button"
              className={
                page === 'personal'
                  ? 'inline-flex p-4 text-black rounded-t-lg border-b-2 border-black active group'
                  : 'inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:border-black hover:text-black group'
              }
              aria-current="page"
              onClick={() => setPage('personal')}
            >
              <div>
                Personal
              </div>
            </button>
          </li>
          <li className="mr-2">
            <button
              type="button"
              className={
                page === 'company'
                  ? 'inline-flex p-4 text-black rounded-t-lg border-b-2 border-black active group'
                  : 'inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:border-black hover:text-black group'
              }
              aria-current="page"
              onClick={() => setPage('company')}
            >
              <div>
                Company
              </div>
            </button>
          </li>
        </ul>
      </div>
      {
        renderSubPage()
      }
    </>
  );
}
export default Settings;
