import { useAuth0 } from '@auth0/auth0-react';
import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { useAccount } from '../../common/AccountContext';
import { FileSubmitResponse } from '../../common/types';

function EndpointDetails() {
  const { getAccessTokenSilently } = useAuth0();
  const fileInput = useRef<HTMLInputElement>(null);
  const { account, setAccount } = useAccount();
  const [fileInputName, setFileInputName] = useState('');
  const [fileBlob, setFileBlob] = useState<File | null>(null);
  const [formFields, setFormFields] = useState([
    { id: uuidv4(), type: '', body: '' },
  ]);
  const textAreaPlaceholder = '{\n\t"deviceId": "string",\n\t"connectorId": "string"\n}';

  const successToast = (response: FileSubmitResponse) => {
    if (account && account.company) {
      const newCompany = {
        ...account.company,
        api_schema_s3_key: response.data.filename,
        api_schema_filename: fileInputName,
      };

      setAccount({
        ...account,
        company: newCompany,
      });
    }
    return <b>File saved!</b>;
  };

  const successToastManual = (response: FileSubmitResponse) => {
    if (account && account.company) {
      const newCompany = {
        ...account.company,
        api_schema_s3_key_manual: response.data.filename,
      };

      setAccount({
        ...account,
        company: newCompany,
      });
    }
    return <b>File saved!</b>;
  };

  const dropHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            setFileInputName(file.name);
            setFileBlob(file);
          }
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file) => {
        setFileInputName(file.name);
        setFileBlob(file);
      });
    }
  };

  const dragOverHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    // document.querySelector('.dropzone-label')?.classList.add('active');
  };

  const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileInputName(event.target.files[0].name);
      setFileBlob(event.target.files[0]);
    }
  };

  const handleFileSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (fileBlob) {
      const fd = new FormData();
      fd.append('data', fileBlob);

      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
        scope: 'openid profile email',
      });

      const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.aeoncharge.com/api'}/cpo/single/${account?.company?.id}`;
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: fd,
      };

      toast.promise(
        fetch(url, options).then((res) => res.json()),
        {
          loading: 'Saving...',
          success: successToast,
          error: <b>Could not save.</b>,
        },
      );
    }
  };

  const download = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const downloadFile = async (key: string, filename: string) => {
    if (key === '' || filename === '') return;

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
      scope: 'openid profile email',
    });

    const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.aeoncharge.com/api'}/cpo/file/${key}`;
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, options);
    const blob = await response.blob();
    download(blob, filename);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const str = JSON.stringify(formFields);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    });
    const fd = new FormData();
    fd.append('data', blob);

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://hub.aeoncharge.com',
      scope: 'openid profile email',
    });

    const url = `${process.env.REACT_APP_HUB_BACKEND_URL || 'https://hub.aeoncharge.com/api'}/cpo/endpoint-details`;
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: fd,
    };

    toast.promise(
      fetch(url, options).then((res) => res.json()),
      {
        loading: 'Saving...',
        success: successToastManual,
        error: <b>Could not save.</b>,
      },
    );
  };

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: string,
  ) => {
    const data = [...formFields];
    const object = data.find((o) => o.id === index);
    if (object) {
      object[
        event.target.name as keyof { id: string, type: string, body: string; }
      ] = event.target.value;
    }
    setFormFields(data);
  };

  const addFields = () => {
    const object = {
      id: uuidv4(),
      type: '',
      body: '',
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index: string) => {
    const data = [...formFields];
    setFormFields(data.filter((o) => o.id !== index));
  };

  return (
    <div className="bg-white rounded-lg p-10 w-full mt-10 m-auto text-lg">
      <form onSubmit={handleFileSubmit} className="grid grid-cols-3 gap-6 mb-12">
        <div className="col-span-3">
          {/* <label
            className="text-2xl text-primary font-medium hover:cursor-pointer"
            htmlFor="dropzone-file"
          >
            Upload OpenAPI Yaml
          </label> */}
          <div className="mt-6 flex justify-center items-center w-full" onDrop={dropHandler} onDragOver={dragOverHandler}>
            <label id="dropzone-label" htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-fit rounded-lg border-2 border-dashed cursor-pointer hover:bg-bray-800 bg-gray-700 border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span>
                  {' '}
                  or drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">YAML or YML (MAX. 10 MB)</p>
              </div>
              <input id="dropzone-file" type="file" onChange={handleFileInput} className="hidden" accept=".yaml, .yml" ref={fileInput} />
            </label>
          </div>
        </div>
        <div>
          {
            fileInputName
          }
        </div>
        <div className="col-span-3 flex justify-between items-center">
          <div className="flex w-full">
            <span className="mr-4 text-gray-400">
              Uploaded:
            </span>
            {
              account?.company?.api_schema_s3_key && account?.company?.api_schema_filename
                ? (
                  <button
                    type="button"
                    onClick={
                      () => downloadFile(
                        account?.company?.api_schema_s3_key || '',
                        account?.company?.api_schema_filename || '',
                      )
                    }
                    aria-label="Download"
                    className="hover:underline text-orange-400 truncate"
                  >
                    {account?.company?.api_schema_filename}
                  </button>
                )
                : 'N/A'
            }
          </div>
          <button
            type="submit"
            className="bg-primary w-fit text-white rounded-lg py-2 px-14 hover:bg-black"
          >
            Save
          </button>
        </div>
      </form>

      {/* <!-- Border --> */}
      <div className="col-span-2">
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-PrimaryDark" />
          <span className="flex-shrink mx-4">Or</span>
          <div className="flex-grow border-t border-PrimaryDark" />
        </div>
      </div>

      <div className="flex w-full">
        <span className="mr-4 text-gray-400">
          Manual input:
        </span>
        {
          account?.company?.api_schema_s3_key_manual
            ? (
              <button
                type="button"
                onClick={
                  () => downloadFile(
                    account?.company?.api_schema_s3_key_manual || '',
                    account?.company?.api_schema_s3_key_manual || '',
                  )
                }
                className="hover:underline text-orange-400 truncate"
              >
                {account?.company?.api_schema_s3_key_manual}
              </button>
            )
            : 'N/A'
        }
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col">
        {formFields.map((form) => (
          <div key={form.id} className="flex flex-col">
            <label htmlFor="evse" className="mt-10 mb-6 text-2xl text-primary">EVSE Endpoint</label>
            <input
              id="evse"
              name="type"
              type="text"
              onChange={(event) => handleFormChange(event, form.id)}
              value={form.type}
              placeholder="Write EVSE endpoint..  ex: POST /api/station/activate"
              className="w-full border-none bg-gray-100 rounded-md"
            />

            <label htmlFor="requestBody" className="mt-10 mb-6 text-2xl text-primary">Request Body</label>
            <textarea
              name="body"
              id="requestBody"
              cols={30}
              rows={10}
              onChange={(event) => handleFormChange(event, form.id)}
              placeholder={textAreaPlaceholder}
              className="border-none bg-gray-100 rounded-md"
            />
            <button type="button" onClick={() => removeFields(form.id)}>Remove</button>
          </div>
        ))}
        <button
          type="button"
          onClick={addFields}
          className="w-fit border rounded-lg py-2 px-14 mt-9 hover:bg-gray-100 self-end"
        >
          + Add Endpoint
        </button>

        <button
          type="submit"
          className="bg-primary w-fit text-white rounded-lg py-2 px-14 mt-9 hover:bg-black self-end"
        >
          Save
        </button>
      </form>
    </div>
  );
}
export default EndpointDetails;
